// 标的资产信息
<template>
  <div class="asseyInfo">
    <!-- 资产信息 -->
    <div class="head">
      <title-center :rules="true" name="资产信息"></title-center>
      <div class="addbutton" @click="addAssetsInfo">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="assetInfoColumn"
      :tableAttrs="{
        data: assetInfoData,
        stripe: true
      }"
      :isCaculateHeight="false"
      :showPage="false"
      ref="assetInfoColumn"
      style="position: relative"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="easNoHeader">
        <div class="fontColor">
          付款单
        </div>
      </template>

      <template slot="finContractIdHeader">
        <font style="color: #ee1c1c">*</font>合同名称
      </template>
      <template slot="finContractNameHeader">
        <font style="color: #ee1c1c">*</font>实际合同名称
      </template>
      <template slot="pledgeValueHeader">
        <font style="color: #ee1c1c">*</font>转让价值(元)
      </template>
      <template slot="relLoanAmountHeader">
        <font style="color: #ee1c1c">*</font>对应融资额(元)
      </template>
      <template slot="pledgeRateHeader">
        <font style="color: #ee1c1c">*</font>质押率(%)
      </template>
      <!-- 付款单 -->
      <template slot="easNo" slot-scope="scope">
        <div style="display:flex">
          <el-input
          v-model="scope.row.easNo"
          placeholder="请选择"
          disabled
        >
        </el-input>
        <icon-button   content="付款单选择"
        @click="addPaymentOrder(scope.row, scope.$index)"
          icon="iconfont iconxuanze" />
        </div>
      </template>
      <!-- 合同名称 -->
      <template slot="finContractId" slot-scope="scope">
        <nwe-base-select
          v-model="scope.row.finContractId"
          filterable
          @change="contractChange(scope.row, scope.$index)"
          :options="contractOptions"
          :selectedField="['finContractId', 'finContractName']"
          :disabled='!!(scope.row.easNo && scope.row.finContractId)'
        ></nwe-base-select>
      </template>
      <!-- 合同编号 -->
      <template slot="finContractCode" slot-scope="scope">
        <el-input
          v-model="scope.row.finContractCode"
          placeholder="请输入"
          :disabled="true"
        ></el-input>
      </template>
      <!-- 实际合同名称 -->
      <template slot="finContractName" slot-scope="scope">
        <el-input
          v-model="scope.row.finContractName"
          placeholder="请输入"
          :clearable="true"
        ></el-input>
      </template>
      <!-- 项目公司名称 -->
      <template slot="xmgsName" slot-scope="scope">
        <el-input
          v-model="scope.row.xmgsName"
          placeholder="请输入"
          :clearable="true"
        ></el-input>
      </template>
      <!-- 合同对价 -->
      <template slot="finContractAmount" slot-scope="scope">
        <amount-input
          v-model="scope.row.finContractAmount"
          placeholder="请输入"
          :maxlength="16"
          type="1000Y"
          @change="
            finContractAmountInput(scope.row.finContractAmount, scope.$index)
          "
          :clearable="true"
        ></amount-input>
      </template>
      <template slot="invoiceNo" slot-scope="scope">
        <sz-input v-model="scope.row.invoiceNo" placeholder="请输入"></sz-input>
      </template>
      <!-- 转让价值 -->
      <template slot="pledgeValue" slot-scope="scope">
        <amount-input
          v-model="scope.row.pledgeValue"
          placeholder="请输入"
          maxlength="16"
          type="1000Y"
          @input="pledgeValueChange(scope.row.pledgeValue, scope.$index)"
          @change="changePledgeValue(scope.row.pledgeValue, scope.$index)"
          :clearable="true"
        ></amount-input>
      </template>
      <!-- 对应融资额 -->
      <template slot="relLoanAmount" slot-scope="scope">
        <amount-input
          v-model="scope.row.relLoanAmount"
          placeholder="请输入"
          maxlength="16"
          type="1000Y"
          @input="changeRelLoanAmount(scope.row.relLoanAmount, scope.$index)"
          @change="relLoanAmountChange(scope.row.relLoanAmount, scope.$index)"
          :clearable="true"
        ></amount-input>
      </template>
      <!-- 质押率 -->
      <template slot="pledgeRate" slot-scope="scope">
        <amount-input
          v-model="scope.row.pledgeRate"
          placeholder="请输入"
          maxlength="16"
          type="BL"
          :clearable="true"
          :disabled="true"
        ></amount-input>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          content="删除"
          @click="delAssets(scope.row, scope.$index)"
          icon="iconfont iconshanchu1"
        ></icon-button>
      </template>
    </base-table>
    <!-- 项目信息 -->
    <!-- <div class="head">
      <title-center :rules="true" name="项目信息"></title-center>
    </div> -->
    <!-- <base-table
      :columns="projectInfoColumn"
      :tableAttrs="{
        data: projectInfoData,
        stripe: true
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="shareholdingRatioHeader">
        <font style="color: #ee1c1c">*</font>交发控股比例
      </template>
      <template slot="controllingEntityHeader">
        <font style="color: #ee1c1c">*</font>交发控股主体
      </template> -->
      <!-- 项目公司城市 -->
      <!-- <template slot="city" slot-scope="scope">
        <el-input
          v-model="scope.row.city"
          placeholder="请输入"
          maxlength="50"
        ></el-input>
      </template> -->
      <!-- 金地控股比例 -->
      <!-- <template slot="shareholdingRatio" slot-scope="scope">
        <amount-input
          v-model="scope.row.shareholdingRatio"
          placeholder="请输入"
          type="BL"
          maxlength="16"
        ></amount-input>
      </template> -->
      <!-- 金地控股主体 -->
      <!-- <template slot="controllingEntity" slot-scope="scope">
        <el-input
          v-model="scope.row.controllingEntity"
          placeholder="请输入"
          maxlength="50"
        ></el-input>
      </template>
    </base-table> -->
    <!-- 新增弹窗 -->
    <add-assets-dialog :visible.sync="visible" :dialogTitle="dialogTitle" />
    <!-- 付款单弹窗 -->
    <add-payment-order
      :visible.sync="paymantVisible"
      :assetInfoData="assetInfoData"
      :paymentIndex="paymentIndex"
      :paymentData="paymentData"
      :contractName="contractName"
      @addPaymentData="addPaymentData"
    ></add-payment-order>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import { assetInfoTable, projectInfoTable } from '../utils/make-config'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import AddAssetsDialog from '../components/add-assets-dialog.vue'
// import BaseSelect from '@/components/common/base-select/base-select.vue'
import NweBaseSelect from '@/components/common/base-select/new-base-select.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import SzInput from '@/components/input/sz-input/sz-input.vue'
import addPaymentOrder from './add-payment-order.vue'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import { assetcontractApi, assetinfoApi } from '@/api/assetApi'
// import { contactApi } from '@/api/contactApi'
import Storage from '@/utils/storage'
import TitleCenter from '@/pages/business/components/title-center.vue'

export default {
  props: {
    showRequired: Boolean
  },
  components: {
    baseTable,
    IconButton,
    AddAssetsDialog,
    AmountInput,
    NweBaseSelect,
    TitleCenter,
    addPaymentOrder,
    SzInput
  },
  data () {
    return {
      assetInfoData: [], // 资产信息数据
      projectInfoData: [], // 项目信息数据
      projectList: [], // 项目数据列表
      visible: false,
      dialogTitle: '', // 弹窗标题
      options: [],
      paymantVisible: false,
      paymentIndex: 0,
      contractName: '',
      paymentData: '',
      contractList: [], // 合同文件列表
      contractOptions: [], // 合同文件下拉选项
      isRules: Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付',
      contractCode: ''
    }
  },
  computed: {
    assetInfoColumn () {
      return assetInfoTable(this)
    },
    projectInfoColumn () {
      return projectInfoTable(this)
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  watch: {
    showRequired (val) {
      if (val) {
        const dom = document.getElementsByClassName('fontColor')
        dom[0].setAttribute('class', 'fontRed')
      } else {
        const dom = document.getElementsByClassName('fontRed')
        dom[0].setAttribute('class', 'fontColor')
      }
    },
    businessId (val) {
      if (val) {
        // this.getAssetsInfo()
      }
    }
  },
  mounted () {
    if (this.businessId) {
      this.getContractList()
      this.getAssetsInfo()
    }
  },
  methods: {
    // 获取标的资产初始化信息
    getAssetsInfo () {
      financeAdmittanceApi
        .assetsInfo({ businessId: this.$route.query.businessId })
        .then(res => {
          this.assetInfoData = res.data.finContractInfoList
            ? res.data.finContractInfoList
            : []
          this.projectInfoData = res.data.finProjectInfoList
            ? res.data.finProjectInfoList
            : []
          this.projectInfoData = this.projectInfoData?.map(item => {
            return { projectAddress: item.province + item.city, ...item }
          })
        })
    },
    // 获取合同列表
    getContractList () {
      const params = {
        businessId: this.businessId,
        mainBorrowerId: Storage.getLocal('admittanceInfo').gysId
      }
      financeAdmittanceApi.getContract(params).then(res => {
        this.contractList = res.data.contractInfos
        this.projectList = res.data.projectInfos
        this.contractList.forEach(item => {
          this.contractOptions.push({
            finContractId: item.keyId,
            finContractName: item.contractName
          })
        })
      })
    },
    // 选择合同带入值
    contractChange (data, index) {
      // 选出全部信息
      const curArr = []
      this.contractList.forEach((item, index) => {
        this.assetInfoData.forEach((ele, i) => {
          if (item.keyId === ele.finContractId) {
            curArr.push(item)
          }
        })
      })
      // 选出项目信息
      const addProjectArr = []
      this.projectList.forEach((item, index) => {
        curArr.forEach((ele, i) => {
          if (item.keyId === ele.projectId) {
            const addData = {
              finContractId: ele.keyId,
              projectName: ele.xmgsName,
              ...item
            }
            addData.projectName = ele.xmgsName
            addProjectArr.push(addData)
          }
        })
      })
      // 去重
      const newAddProjectArr = []
      addProjectArr.forEach(item => {
        if (!newAddProjectArr.some(items => items?.finContractId === item.finContractId)) {
          newAddProjectArr.push(item)
        }
      })
      this.projectInfoData = newAddProjectArr
      // 置空选项
      if (!data.finContractId) {
        this.assetInfoData[index].finContractCode = ''
        this.assetInfoData[index].finContractName = ''
        this.assetInfoData[index].finContractAmount = ''
        this.assetInfoData[index].xmgsName = ''
        this.assetInfoData[index].xmgsId = ''
        this.assetInfoData[index].projectId = ''
        this.assetInfoData[index].pledgeValue = ''
        this.assetInfoData[index].gysId = ''
        this.assetInfoData[index].pledgeRate = ''
        this.assetInfoData[index].relLoanAmount = ''
        return false
      }
      // 选出默认值
      const [curOption] = this.contractList.filter(
        item => data.finContractId === item.keyId
      )
      this.$set(
        this.assetInfoData[index],
        'finContractCode',
        curOption.contractCode
      )
      this.$set(
        this.assetInfoData[index],
        'finContractName',
        curOption.contractName
      )
      this.$set(
        this.assetInfoData[index],
        'finContractAmount',
        curOption.contractAmount
      )
      this.$set(this.assetInfoData[index], 'projectId', curOption.projectId)
      this.$set(this.assetInfoData[index], 'xmgsName', curOption.xmgsName)
      this.$set(this.assetInfoData[index], 'xmgsId', curOption.xmgsId)
      this.$set(
        this.assetInfoData[index],
        'pledgeValue',
        curOption.pledgeValue
      )
      this.$set(this.assetInfoData[index], 'gysId', curOption.gysId)
    },

    // 新增资产信息
    addAssetsInfo () {
      console.log(this.assetInfoData)
      this.assetInfoData.push({ add: true })
    },
    // 删除资产信息
    async delAssets (data, index) {
      console.log(
        this.assetInfoData,
        data,
        this.projectInfoData,
        'projectInfoData'
      )
      // 删除后端绑定的付款单
      if (data.finContractId && data.easNo && data.keyId) {
        const res = await assetinfoApi.updateDelStatus({ easNos: data.easNo, contractId: data.finContractId, finContractInfoKeyId: data.keyId })
        if (res.data && res.data.code === '1') {
          console.log(res.data.message)
        }
      }
      // 删除资产信息
      this.assetInfoData.splice(index, 1)
      if (!data.finContractId) {
        return
      }
      // 删除对应的项目信息
      const [dleOption] = this.contractList.filter(
        item => data.finContractId === item.keyId
      )
      console.log(dleOption, 'dleOption')
      this.projectInfoData.forEach((item, index) => {
        if (item.finContractId === dleOption.keyId) {
          this.projectInfoData.splice(index, 1)
        }
      })

      // 稳享付自动计算
      if (Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付') {
        this.countPledgeRate()
      }
    },
    // 合同名称改变
    finContractChange () {},

    // 对应融资额修改
    changeRelLoanAmount (value, index) {
      if (value && this.assetInfoData[index].pledgeValue) {
        this.$set(
          this.assetInfoData[index],
          'pledgeRate',
          ((value / this.assetInfoData[index].pledgeValue) * 100).toFixed(2)
        )
      }
      if (!value) {
        this.$set(this.assetInfoData[index], 'pledgeRate', '')
      }
      // 稳享付自动计算
      if (Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付') {
        this.countPledgeRate()
      }
      this.$emit('changePledgeRate', this.assetInfoData)
    },
    // 合同对价
    finContractAmountInput (value, index) {

    },
    // 质押关系
    changePledgeValue (value, index) {
      // 稳享付自动计算
      if (Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付') {
        this.countPledgeRate()
      }
    },
    relLoanAmountChange (value, index) {
      // 稳享付自动计算
      if (Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付') {
        this.countPledgeRate()
      }
    },
    // 转让价值修改
    pledgeValueChange (value, index) {
      if (value && this.assetInfoData[index].relLoanAmount) {
        this.$set(
          this.assetInfoData[index],
          'pledgeRate',
          ((this.assetInfoData[index].relLoanAmount / value) * 100).toFixed(2)
        )
      } else {
        this.$set(this.assetInfoData[index], 'pledgeRate', '')
      }
      // 稳享付自动计算
      if (Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付') {
        this.countPledgeRate()
      }
      if (value === '0' || !value) {
        this.warning('请输入转让价值且转让价值必须大于0')
        return false
      }
      this.$emit('changePledgeRate', this.assetInfoData)
    },
    // 计算基本信息质押率
    countPledgeRate () {
      let allPledgeValue = 0
      let allRelLoanAmount = 0
      let isComplete = false // 数据是否完整
      this.assetInfoData.forEach((item, index) => {
        allPledgeValue = allPledgeValue + item.pledgeValue * 1
        allRelLoanAmount = allRelLoanAmount + item.relLoanAmount * 1
        if (!item.pledgeValue || !item.relLoanAmount) {
          isComplete = true
        }
      })
      console.log(isComplete, 'isComplete')
      const rate = ((allRelLoanAmount / allPledgeValue) * 100).toFixed(2)
      if (rate) {
        this.$parent.chanegPledgeRate(rate, isComplete)
      }
    },
    // 添加付款单
    addPaymentOrder (data, index) {
      this.paymentIndex = index
      this.paymentData = data.easNo
      this.contractName = data.finContractName || ''
      this.contractCode = data.finContractCode
      this.paymantVisible = true
    },
    // 资产列表数据付款单赋值
    async  addPaymentData (data) {
      console.log(data)
      // 选择付款单时清空转让价值
      if (data.length > 0) {
        // 没有选择合同根据付款单查询
        const newData = JSON.parse(JSON.stringify(data))
        // 先插入选中的值 保证选的位置后插入
        if (newData.length > 0) {
          const addData = newData.map(item => {
            return { easNo: item.easNo, finContractId: item.contractid, paymentApplyAmount: item.paymentApplyAmount }
          })
          this.assetInfoData.splice(this.paymentIndex, 1, ...addData)
        }
        const contractIds = newData.map(item => item.contractid)
        const params = {
          contractIds: [...new Set(contractIds)].join(',')
        }
        const res = await assetcontractApi.contractByContractIds(params)
        const contractInfos = res.data.contractInfos
        this.assetInfoData.forEach((item, index) => {
          contractInfos.forEach(ele => {
            if (item.finContractId === ele.keyId) {
              const obj = {
                easNo: item.easNo,
                finContractCode: ele.contractCode,
                finContractName: ele.contractName || '',
                finContractId: ele.keyId,
                finContractAmount: ele.contractAmount || '',
                xmgsName: ele.xmgsName || '',
                xmgsId: ele.xmgsId || '',
                projectId: ele.projectId || '',
                pledgeValue: '',
                gysId: ele.gysId || '',
                pledgeRate: ele.pledgeRate || '',
                relLoanAmount: ele.relLoanAmount || '',
                paymentApplyAmount: item.paymentApplyAmount // 付款单申报金额
              }
              this.assetInfoData.splice(index, 1, obj)
              this.contractChange(item, index)
            }
          })
        })
        console.log(this.assetInfoData, contractInfos, 'this.assetInfoData')
        // 处理相同合同不同付款单
        data.forEach((ele, index) => {
          const indexs = this.paymentIndex + index
          let obj = {}
          for (let i = 0; i < this.assetInfoData.length; i++) {
            if (this.assetInfoData[i].easNo === ele.easNo) {
              return false
            }
          }
          contractInfos.forEach(eles => {
            if (ele.contractid === eles.keyId) {
              obj = {
                easNo: ele.easNo,
                finContractCode: eles.contractCode,
                finContractName: eles.contractName,
                finContractId: eles.keyId,
                finContractAmount: eles.contractAmount || '',
                xmgsName: eles.xmgsName || '',
                xmgsId: eles.xmgsId || '',
                projectId: eles.projectId || '',
                pledgeValue: '',
                gysId: eles.gysId,
                pledgeRate: eles.pledgeRate || '',
                relLoanAmount: eles.relLoanAmount || '',
                paymentApplyAmount: ele.paymentApplyAmount // 付款单申报金额
              }
            }
          })
          let state = false
          // 已经有的付款单不重新增加一行。
          this.assetInfoData.forEach(eles => {
            if (ele.easNo === eles.easNo) {
              state = true
            }
          })
          if (!state) {
            this.assetInfoData.splice(indexs, 1, obj)
            this.contractChange(obj, indexs)
          }
        })
      } else {
        this.assetInfoData.forEach(ele => {
          if (this.contractCode === ele.finContractCode) {
            this.$set(this.assetInfoData[this.paymentIndex], 'pledgeValue', '')
            this.$set(this.assetInfoData[this.paymentIndex], 'easNo', '')
            this.$set(this.assetInfoData[this.paymentIndex], 'paymentApplyAmount', '')
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.fontRed:before {
  content: "*";
  display: inline-block;
  width: 10px;
  height: 10px;
  color: #ee1c1c
}
.asseyInfo {
  padding: 10px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding-bottom: 10px;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
